{
  "Loyal beetle": "Верная пчела",
  "Drunken torchbearer": "Пьяный факелоносец",
  "Pack rat": "Вьючная крыса",
  "torchbearer": "Факелоносец",
  "Labourer": "Рабочий",
  "Tunnel digger": "Тоннельщик",
  "Armourer/blacksmith": "Кузнец/оружейник",
  "Local guide": "Проводник",
  "Mouse-at-arms": "Наёмыш",
  "Scholar": "Учёный",
  "Knight": "Рыцарь",
  "Interpreter": "Переводчик"
}