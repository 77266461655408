{
  "Test subject": "Test subject",
  "Lead coat": "Lead coat",
  "Magic missile": "Magic missile",
  "Kitchen forager": "Kitchen forager",
  "Shield & jerkin": "Shield & jerkin",
  "Cookpots": "Cookpots",
  "Cage dweller": "Cage dweller",
  "Bottle of milk": "Bottle of milk",
  "Hedge witch": "Hedge witch",
  "Incense stick": "Incense stick",
  "Leatherworker": "Leatherworker",
  "Shears": "Shears",
  "Street tough": "Street tough",
  "Flask of coffee": "Flask of coffee",
  "Mendicant priest": "Mendicant priest",
  "Holy symbol": "Holy symbol",
  "Beetleherd": "Beetleherd",
  "Pole, 6\"": "Pole, 6\"",
  "Ale brewer": "Ale brewer",
  "Small barrel of ale": "Small barrel of ale",
  "Fishermouse": "Fishermouse",
  "Net": "Net",
  "Blacksmith": "Blacksmith",
  "Metal file": "Metal file",
  "Wireworker": "Wireworker",
  "Wire, spool": "Wire, spool",
  "Woodcutter": "Woodcutter",
  "Twine, roll": "Twine, roll",
  "Bat cultist": "Bat cultist",
  "Bag of bat teeth": "Bag of bat teeth",
  "Tin miner": "Tin miner",
  "Pickaxe": "Pickaxe",
  "Trash collector": "Trash collector",
  "Trashhook": "Trashhook",
  "Mirror": "Mirror",
  "Wall rover": "Wall rover",
  "Fishhook": "Fishhook",
  "Thread, spool": "Thread, spool",
  "Merchant": "Merchant",
  "20p IOU from a noblemouse": "20p IOU from a noblemouse",
  "Raft crew": "Raft crew",
  "Wooden spikes": "Wooden spikes",
  "Worm wrangler": "Worm wrangler",
  "Soap": "Soap",
  "Sparrow rider": "Sparrow rider",
  "Goggles": "Goggles",
  "Sewer guide": "Sewer guide",
  "Prison guard": "Prison guard",
  "Chain, 6\"": "Chain, 6\"",
  "Fungus farmer": "Fungus farmer",
  "Dried mushroom": "Dried mushroom",
  "Spore mask": "Spore mask",
  "Dam builder": "Dam builder",
  "Shovel": "Shovel",
  "Cartographer": "Cartographer",
  "Quill & ink": "Quill & ink",
  "Compass": "Compass",
  "Trap thief": "Trap thief",
  "Block of cheese": "Block of cheese",
  "Glue": "Glue",
  "Vagabond": "Vagabond",
  "Tent": "Tent",
  "Treasure map, dubious": "Treasure map, dubious",
  "Grain farmer": "Grain farmer",
  "Whistle": "Whistle",
  "Message runner": "Message runner",
  "Bedroll": "Bedroll",
  "Documents, sealed": "Documents, sealed",
  "Troubadour": "Troubadour",
  "Musical instrument": "Musical instrument",
  "Disguise kit": "Disguise kit",
  "Gambler": "Gambler",
  "Set of loaded dice": "Set of loaded dice",
  "Sap tapper": "Sap tapper",
  "Bucket": "Bucket",
  "Bee keeper": "Bee keeper",
  "Jar of honey": "Jar of honey",
  "Librarian": "Librarian",
  "Scrap of obscure book": "Scrap of obscure book",
  "Pauper noblemouse": "Pauper noblemouse",
  "Felt hat": "Felt hat",
  "Perfume": "Perfume"
}