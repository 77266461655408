{
  "Star": "Star",
  "Brave": "Brave",
  "Reckless": "Reckless",
  "Wheel": "Wheel",
  "Industrious": "Industrious",
  "Unimaginative": "Unimaginative",
  "Acorn": "Acorn",
  "Inquisitive": "Inquisitive",
  "Stubborn": "Stubborn",
  "Storm": "Storm",
  "Generous": "Generous",
  "Wrathful": "Wrathful",
  "Moon": "Moon",
  "Wise": "Wise",
  "Mysterious": "Mysterious",
  "Mother": "Mother",
  "Nurturing": "Nurturing",
  "Worrying": "Worrying",
  "Chocolate": "Chocolate",
  "Black": "Black",
  "White": "White",
  "Tan": "Tan",
  "Grey": "Grey",
  "Blue": "Blue",
  "Solid": "Solid",
  "Brindle": "Brindle",
  "Patchy": "Patchy",
  "Banded": "Banded",
  "Marbled": "Marbled",
  "Flecked": "Flecked",
  "Scarred body": "Scarred body",
  "Corpulent body": "Corpulent body",
  "Skeletal body": "Skeletal body",
  "Willowy body": "Willowy body",
  "Tiny body": "Tiny body",
  "Massive body": "Massive body",
  "War paint": "War paint",
  "Foreign clothes": "Foreign clothes",
  "Elegant clothes": "Elegant clothes",
  "Patched clothes": "Patched clothes",
  "Fashionable clothes": "Fashionable clothes",
  "Unwashed clothes": "Unwashed clothes",
  "Missing ear": "Missing ear",
  "Lumpy face": "Lumpy face",
  "Beautiful face": "Beautiful face",
  "Round face": "Round face",
  "Delicate face": "Delicate face",
  "Elongated face": "Elongated face",
  "Groomed fur": "Groomed fur",
  "Dreadlocks": "Dreadlocks",
  "Dyed fur": "Dyed fur",
  "Shaved fur": "Shaved fur",
  "Frizzy fur": "Frizzy fur",
  "Silky fur": "Silky fur",
  "Night black eyes": "Night black eyes",
  "Eye patch": "Eye patch",
  "Blood red eyes": "Blood red eyes",
  "Wise eyes": "Wise eyes",
  "Sharp eyes": "Sharp eyes",
  "Luminous eyes": "Luminous eyes",
  "Cropped tail": "Cropped tail",
  "Whip-like tail": "Whip-like tail",
  "Tufted tail": "Tufted tail",
  "Stubby tail": "Stubby tail",
  "Prehensile tail": "Prehensile tail",
  "Curly tail": "Curly tail"
}