{
  "Languages": "Languages",
  "Welcome to Mausritter Sheet!": "Welcome to Mausritter Sheet!",
  "under licence": "under license",
  "Items": "Items",
  "Conditions": "Conditions",
  "Birthsign": "Birthsign",
  "Coat color": "Coat color",
  "Coat pattern": "Coat pattern",
  "Color": "Color",
  "Pattern": "Pattern",
  "Main paw": "Main paw",
  "Body": "Body",
  "Off paw": "Off paw",
  "Carried: Ready to use.": "Carried: Ready to use.",
  "Worn: Quick to ready.": "Worn: Quick to ready.",
  "Pack: Takes time to ready. During combat, requires an action to retrieve.": "Pack: Takes time to ready. During combat, requires an action to retrieve.",
  "Name": "Name",
  "Background": "Background",
  "Coat": "Coat",
  "Look": "Look",
  "STR": "STR",
  "DEX": "DEX",
  "WIL": "WIL",
  "STR ⇄ DEX": "STR ⇄ DEX",
  "STR ⇄ WIL": "STR ⇄ WIL",
  "DEX ⇄ WIL": "DEX ⇄ WIL",
  "No swap.": "No swap.",
  "{price}p": "{price}p",
  "HP": "HP",
  "Max": "Max",
  "Current": "Current",
  "Inventory": "Inventory",
  "Level": "Level",
  "XP": "XP",
  "Grit": "Grit",
  "Ignore a number of conditions equal to your Grit.": "Ignore a number of conditions equal to your Grit.",
  "Bank": "Bank",
  "Pips": "Pips",
  "Roll:": "Roll:",
  "Roll: ": "Roll: ",
  "About": "About",
  "This application is an independent production by Daniel Coquette and is not affiliated with Losing Games. It is published under the Mausritter Third Party Licence.": "This application is an independent production by Daniel Coquette and is not affiliated with Losing Games. It is published under the Mausritter Third Party Licence.",
  "Mausritter is copyright Losing Games.": "Mausritter is copyright Losing Games.",
  "This application is copyright Daniel Coquette.": "This application is copyright Daniel Coquette.",
  "Sheet and drawer backgrounds from DevianArt under license": "Sheet and drawer backgrounds from DevianArt under license",
  "Close": "Close",
  "Neutral": "Neutral",
  "With advantage": "With advantage",
  "With disadvantage": "With disadvantage",
  "Need {score} or lower, roll {roll}.": "Need {score} or lower, roll {roll}.",
  "Need upper than {upper}, roll {roll}.": "Need upper than {upper}, roll {roll}.",
  "Create a new character...": "Create a new character...",
  "Load": "Load",
  "Save": "Save",
  "Saves": "Saves",
  "Delete": "Delete",
  "Empty": "Empty",
  "SUCCESS": "SUCCESS",
  "FAILED": "FAILED",
  "Clear:": "Clear:",
  "STR save": "STR save",
  "DEX save": "DEX save",
  "WIL save": "WIL save",
  "Choose...": "Choose...",
  "Weapon": "Weapon",
  "Improvised": "Improvised",
  "Needle": "Needle",
  "Dagger": "Dagger",
  "Axe": "Axe",
  "Sword": "Sword",
  "Mace": "Mace",
  "Hammer": "Hammer",
  "Warhammer": "Warhammer",
  "Spear": "Spear",
  "Hookarm": "Hookarm",
  "Bow": "Bow",
  "Arrows": "Arrows",
  "Sling": "Sling",
  "Stones": "Stones",
  "Light Armour": "Light Armour",
  "Heavy Armour": "Heavy Armour",
  "Torches": "Torches",
  "Lantern": "Lantern",
  "Electric Lantern": "Electric Lantern",
  "Pip Purse": "Pip Purse",
  "Rations": "Rations",
  "Spell": "Spell",
  "Item": "Item",
  "A custom item.": "A custom item.",
  "The sheet of {name} will be erased. Do you confirm?": "The sheet of {name} will be erased. Do you confirm?",
  "Confirm": "Confirm",
  "Yes": "Yes",
  "No": "No",
  "OK": "OK",
  "Def": "Def",
  "Items and conditions drawer.": "Items and conditions drawer.",
  "Roll {dice}": "Roll {dice}",
  "{name} prepares for adventure...": "{name} prepares for adventure...",
  "You may swap any two attributes.": "You may swap any two attributes.",
  "You may choose a weapon below:": "You may choose a weapon below:",
  "You can take one item below:": "You can take one item below:",
  "Item from inherited your background of «{background}»": "Item from inherited your background of «{background}»",
  "Your mouse must pay a fee of 1% of the value when retrieving the stored pips or items.": "Your mouse must pay a fee of 1% of the value when retrieving the stored pips or items.",
  "Retrieving {name} from your bank will cost you {fee} pips. Confirm?": "Retrieving {name} from your bank will cost you {fee} pips. Confirm?",
  "You do need to have {fee} pips to retrieve {name}!": "You do need to have {fee} pips to retrieve {name}!",
  "One paw": "One paw",
  "Both paws": "Both paws",
  "History": "History",
  "Manage your characters.": "Manage your characters.",
  "The sheet of {dest} will be overwritten by {name}. Do you confirm?": "The sheet of {dest} will be overwritten by {name}. Do you confirm?",
  "Do you want to save the sheet of {name}?": "Do you want to save the sheet of {name}?",
  "Welcome": "Welcome",
  "Hirelings": "Hirelings",
  "Recruit hireling...": "Recruit hireling...",
  "Wages/day": "Wages/day",
  "Dismiss": "Dismiss",
  "The hireling of {desc} {name} will be dismissed. Do you confirm?": "The hireling of {desc} {name} will be dismissed. Do you confirm?",
  "Clear": "Clear",
  "Export": "Export",
  "{name} is now copied to clipboard.": "{name} is now copied to clipboard.",
  "Import...": "Import...",
  "Paste your character here...": "Paste your character here...",
  "Drop item here, in your bank.": "Drop item here, in your bank.",
  "Advancement...": "Advancement...",
  "Level up": "Level up",
  "{name} prepares advancement...": "{name} prepares advancement...",
  "{name} will move from the {current} level to the {next} level.": "{name} will move from the {current} level to the {next} level.",
  "Short rest...": "Short rest...",
  "Short rest": "Short rest",
  "Long rest...": "Long rest...",
  "Long rest": "Long rest",
  "Full rest...": "Full rest...",
  "Full rest": "Full rest",
  "A short rest lasts 1 turn and restore d6+1 HP. Do you confirm?": "A short rest lasts 1 turn and restore d6+1 HP. Do you confirm?",
  "A full rest is a week back in safety. It will cost you around 20 pips. Do you confirm?": "A full rest is a week back in safety. It will cost you around 20 pips. Do you confirm?",
  "Current HP is {value}.": "Current HP is {value}.",
  "You need to eat and sleep for a watch to do a long rest. All HP restored. Restore d6 to each attributes if HP was at max. Do you confirm?": "You need to eat and sleep for a watch to do a long rest. All HP restored. Restore d6 to each attributes if HP was at max. Do you confirm?",
  "Restored all HP.": "Restored all HP.",
  "Restored all attributes.": "Restored all attributes.",
  "Restored some attributes.": "Restored some attributes.",
  "Current STR is {value}.": "Current STR is {value}.",
  "Current DEX is {value}.": "Current DEX is {value}.",
  "Current WIL is {value}.": "Current WIL is {value}.",
  "Do not forget to remove the conditions corresponding to this rest.": "Do not forget to remove the conditions corresponding to this rest.",
  "Increase in attributes if d20 is higher than the maximum of the latter.": "Increase in attributes if d20 is higher than the maximum of the latter.",
  "If {roll} is greater than your maximum HP, then your HP is the sum of the dice. Otherwise they increase by one.": "If {roll} is greater than your maximum HP, then your HP is the sum of the dice. Otherwise they increase by one.",
  "Roll {formula} is {roll}. Max {attr} is {value}.": "Roll {formula} is {roll}. Max {attr} is {value}.",
  "Increase {attr} to {result}.": "Increase {attr} to {result}.",
  "{attr} will not increase.": "{attr} will not increase.",
  "You're encumbered. You cannot run, and makes all saves with disadvantage.": "You're encumbered. You cannot run, and makes all saves with disadvantage.",
  "Condolence, your mouse has passed away.": "Condolence, your mouse has passed away.",
  "Your mouse cannot move.": "Your mouse cannot move.",
  "Your mouse is succumbing to madness.": "Your mouse is succumbing to madness.",
  "Preferences...": "Preferences...",
  "Application font": "Application font",
  "3D dices from Anton Natarov": "3D dices from Anton Natarov",
  "Welcome, History and Hirelings background from": "Welcome, History and Hirelings background from",
  "App": "App",
  "Mausritter Sheet folder is {folder}": "Mausritter Sheet folder is {folder}",
  "level {n}": "level {n}",
  "A small custom item.": "A small custom item.",
  "A tall custom item.": "A tall custom item.",
  "A wide custom item.": "A wide custom item.",
  "If you leave before saving, your changes will be lost.": "If you leave before saving, your changes will be lost.",
  "Table": "Table",
  "None": "None",
  "Damages": "Damages",
  "Please create or load your mouse.": "Please create or load your mouse."
}