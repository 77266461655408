{
  "Star": "Звезда",
  "Brave": "Храбрый",
  "Reckless": "Безрассудный",
  "Wheel": "Колесо",
  "Industrious": "Трудолюбивый",
  "Unimaginative": "Приземлённый",
  "Acorn": "Жёлудь",
  "Inquisitive": "Любознательный",
  "Stubborn": "Упрямый",
  "Storm": "Буря",
  "Generous": "Благородный",
  "Wrathful": "Гневливый",
  "Moon": "Луна",
  "Wise": "Мудрый",
  "Mysterious": "Таинственный",
  "Mother": "Мать",
  "Nurturing": "Заботливый",
  "Worrying": "Тревожный",
  "Chocolate": "Шоколадный",
  "Black": "Чёрный",
  "White": "Белый",
  "Tan": "Коричневый",
  "Grey": "Серый",
  "Blue": "Голубой",
  "Solid": "Однотонная шкурка",
  "Brindle": "Пегий",
  "Patchy": "Пятнистый",
  "Banded": "Полосатый",
  "Marbled": "Мраморный",
  "Flecked": "В крапинку",
  "Scarred body": "Шрамы",
  "Corpulent body": "Упитанный",
  "Skeletal body": "Тощий",
  "Willowy body": "Стройный",
  "Tiny body": "Крошечный",
  "Massive body": "Крупный",
  "War paint": "Боевой раскрас",
  "Foreign clothes": "Иноземная одежда",
  "Elegant clothes": "Изящная одежда",
  "Patched clothes": "Залатанная одежда",
  "Fashionable clothes": "Модная одежда",
  "Unwashed clothes": "Нестираная одежда",
  "Missing ear": "Нет уха",
  "Lumpy face": "Бугристая мордочка",
  "Beautiful face": "Красивая мордочка",
  "Round face": "Круглая мордочка",
  "Delicate face": "Изящная мордочка",
  "Elongated face": "Длинная мордочка",
  "Groomed fur": "Ухоженный мех",
  "Dreadlocks": "Свалявшиеся космы",
  "Dyed fur": "Крашеный мех",
  "Shaved fur": "Выбритый мех",
  "Frizzy fur": "Кудрявый мех",
  "Silky fur": "Шелковистый мех",
  "Night black eyes": "Чёрные как ночь глаза",
  "Eye patch": "Повязка на глазу",
  "Blood red eyes": "Кроваво-красные глаза",
  "Wise eyes": "Мудрые глаза",
  "Sharp eyes": "Проницательные глаза",
  "Luminous eyes": "Сияющие глаза",
  "Cropped tail": "Обрезанный хвост",
  "Whip-like tail": "Хлыстообразный хвост",
  "Tufted tail": "Хвост с пушком",
  "Stubby tail": "Короткий хвост",
  "Prehensile tail": "Цепкий хвост",
  "Curly tail": "Хвост крючком"
}