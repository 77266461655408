{
  "Star": "Estrella",
  "Brave": "Valiente",
  "Reckless": "Descuidado",
  "Wheel": "Rueda",
  "Industrious": "Diligente",
  "Unimaginative": "Sin imaginación",
  "Acorn": "Bellota",
  "Inquisitive": "Inquisitivo",
  "Stubborn": "Necio",
  "Storm": "Tormenta",
  "Generous": "Generoso",
  "Wrathful": "Iracundo",
  "Moon": "Luna",
  "Wise": "Sabio",
  "Mysterious": "Misterioso",
  "Mother": "Madre",
  "Nurturing": "Protector",
  "Worrying": "Preocupado",
  "Chocolate": "Chocolate",
  "Black": "Negro",
  "White": "Blanco",
  "Tan": "Pardo",
  "Grey": "Gris",
  "Blue": "Azul",
  "Solid": "Sólido",
  "Brindle": "Manchas grandes",
  "Patchy": "Dispar",
  "Banded": "Con bandas",
  "Marbled": "Jaspeado",
  "Flecked": "Moteado",
  "Scarred body": "Cuerpo cicatrizado",
  "Corpulent body": "Corpulento",
  "Skeletal body": "Esquelético",
  "Willowy body": "Esbelto",
  "Tiny body": "Diminuto",
  "Massive body": "Enorme",
  "War paint": "Pintura de guerra",
  "Foreign clothes": "Ropajes extranjeros",
  "Elegant clothes": "Ropajes elegantes",
  "Patched clothes": "Ropajes remendado",
  "Fashionable clothes": "Ropajes de moda",
  "Unwashed clothes": "Ropajes sucios",
  "Missing ear": "Oreja amputada",
  "Lumpy face": "Rostro abultado",
  "Beautiful face": "Rostro hermoso",
  "Round face": "Rostro redondeado",
  "Delicate face": "Rostro delicado",
  "Elongated face": "Rostro alargado",
  "Groomed fur": "Pelaje cuidado",
  "Dreadlocks": "Rastas",
  "Dyed fur": "Pelaje teñido",
  "Shaved fur": "Pelaje afeitado",
  "Frizzy fur": "Pelaje encrespado",
  "Silky fur": "Pelaje sedoso",
  "Night black eyes": "Ojos negros",
  "Eye patch": "Parche en el ojo",
  "Blood red eyes": "Ojos rojo sangre",
  "Wise eyes": "Mirada sabia",
  "Sharp eyes": "Mirada aguda",
  "Luminous eyes": "Ojos luminosos",
  "Cropped tail": "Cola recortada",
  "Whip-like tail": "Cola fina",
  "Tufted tail": "Cola anudada",
  "Stubby tail": "Cola rechoncha",
  "Prehensile tail": "Cola prensil",
  "Curly tail": "Cola rizada"
}