{
  "Fireball": "Bola de fuego",
  "Heal": "Curar",
  "Magic Missile": "Proyectil mágico",
  "Fear": "Miedo",
  "Darkness": "Oscuridad",
  "Restore": "Restaurar",
  "Be Understood": "Comprensión",
  "Ghost Beetle": "Escarabajo fantasma",
  "Light": "Luz cegadora",
  "Invisible Ring": "Anillo invisible",
  "Knock": "Apertura",
  "Grease": "Grasa",
  "Grow": "Crecimiento",
  "Invisibility": "Invisibilidad",
  "Catnip": "Trampa de gatos"
}