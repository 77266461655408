{
  "Languages": "Sprachen",
  "Welcome to Mausritter Sheet!": "Willkommen zum Mausritter-Charakterbogen!",
  "under licence": "unter der Lizenz",
  "Items": "Gegenstände",
  "Conditions": "Zustände",
  "Birthsign": "Geburtszeichen",
  "Coat color": "Fellfarbe",
  "Coat pattern": "Fellmuster",
  "Color": "Farbe",
  "Pattern": "Muster",
  "Main paw": "Hauptpfote",
  "Body": "Körper",
  "Off paw": "Nebenpfote",
  "Carried: Ready to use.": "Zur Hand: Einsatzbereit",
  "Worn: Quick to ready.": "Getragen: Schnell bereit",
  "Pack: Takes time to ready. During combat, requires an action to retrieve.": "Gepackt: Braucht Zeit zum Bereitmachen. Benötigt im Kampf eine Aktion.",
  "Name": "Name",
  "Background": "Hintergrund",
  "Coat": "Fell",
  "Look": "Körperliches Merkmal",
  "STR": "STÄ",
  "DEX": "GES",
  "WIL": "WIL",
  "STR ⇄ DEX": "STÄ ⇄ GES",
  "STR ⇄ WIL": "STÄ ⇄ WIL",
  "DEX ⇄ WIL": "GES ⇄ WIL",
  "No swap.": "Nicht tauschen.",
  "{price}p": "{price} Kerne",
  "HP": "TP",
  "Max": "Maximal",
  "Current": "Aktuell",
  "Inventory": "Inventar",
  "Level": "Stufe",
  "XP": "EP",
  "Grit": "Mumm",
  "Ignore a number of conditions equal to your Grit.": "Ignoriere eine Anzahl Zustände gleich deines Mumms",
  "Bank": "Bank",
  "Pips": "Kerne",
  "Roll:": "Wurf:",
  "Roll: ": "Wurf: ",
  "About": "Über",
  "This application is an independent production by Daniel Coquette and is not affiliated with Losing Games. It is published under the Mausritter Third Party Licence.": "Diese Anwendung ist eine unabhängige Produktion von Daniel Coquette und steht in keiner Verbindung zu Losing Games. Sie wird unter der Mausritter Third Party Licence veröffentlicht.",
  "Mausritter is copyright Losing Games.": "Das Copyright für Mausritter liegt bei Losing Games.",
  "This application is copyright Daniel Coquette.": "Das Copyright für diese Anwendung liegt bei Daniel Coquette.",
  "Sheet and drawer backgrounds from DevianArt under license": "Charaterbogen- und Schubladenhintergründe von DevianArt unter deren Lizenz",
  "Close": "Schließen",
  "Neutral": "Neutral",
  "With advantage": "Mit Vorteil",
  "With disadvantage": "Mit Nachteil",
  "Need {score} or lower, roll {roll}.": "Benötigt {score} oder niedriger, gewürfelt {roll}.",
  "Need upper than {upper}, roll {roll}.": "Benötigt mehr als {upper}, gewürfelt {roll}.",
  "Create a new character...": "Erstelle einen neuen Charakter...",
  "Load": "Laden",
  "Save": "Speichern",
  "Saves": "Speicherstände",
  "Delete": "Löschen",
  "Empty": "Leer",
  "SUCCESS": "ERFOLG",
  "FAILED": "FEHLSCHLAG",
  "Clear:": "Erholung:",
  "STR save": "STÄ-Probe",
  "DEX save": "GES-Probe",
  "WIL save": "WIL-Probe",
  "Choose...": "Wähle...",
  "Weapon": "Waffe",
  "Improvised": "Improvisiert",
  "Needle": "Nadel",
  "Dagger": "Dolch",
  "Axe": "Axt",
  "Sword": "Schwert",
  "Mace": "Morgenstern",
  "Hammer": "Hammer",
  "Warhammer": "Kriegshammer",
  "Spear": "Speer",
  "Hookarm": "Enterhaken",
  "Bow": "Bogen",
  "Arrows": "Pfeile",
  "Sling": "Schleuder",
  "Stones": "Steine",
  "Light Armour": "Leichte Rüstung",
  "Heavy Armour": "Schwere Rüstung",
  "Torches": "Fackeln",
  "Lantern": "Laterne",
  "Electric Lantern": "Elektrische Laterne",
  "Pip Purse": "Kernbeutel",
  "Rations": "Rationen",
  "Spell": "Zauber",
  "Item": "Gegenstand",
  "A custom item.": "Einen eigenen Gegenstand.",
  "The sheet of {name} will be erased. Do you confirm?": "Der Charakterbogen von {name} wird gelöscht. Trotzdem weitermachen?",
  "Confirm": "Bestätigen",
  "Yes": "Ja",
  "No": "Nein",
  "OK": "OK",
  "Def": "VTD",
  "Items and conditions drawer.": "Gegenstand und Zustand Schublade",
  "Roll {dice}": "Würfel {dice}",
  "{name} prepares for adventure...": "{name} macht sich bereit für Abenteuer...",
  "You may swap any two attributes.": "Du kannst zwei Attribute tauschen.",
  "You may choose a weapon below:": "Wähle eine der folgenden Waffen:",
  "You can take one item below:": "Wähle einen der folgenden Gegenstände:",
  "Item from inherited your background of «{background}»": "Gegenstand von deinem Hintergrund als «{background}»",
  "Your mouse must pay a fee of 1% of the value when retrieving the stored pips or items.": "Deine Maus muss eine Gebühr von 1% des Wertes zahlen, wenn sie die gelagerten Kerne oder Gegenstände abholt.",
  "Retrieving {name} from your bank will cost you {fee} pips. Confirm?": "{name} von der Bank abzuholen kostet dich {fee} Kerne. Bestätigen?",
  "You do need to have {fee} pips to retrieve {name}!": "Du benötigts {fee} Kerne um {name} abzuholen!",
  "One paw": "Einpfotig",
  "Both paws": "Beidpfotig",
  "History": "Verlauf",
  "Manage your characters.": "Verwalte deine Charaktere.",
  "The sheet of {dest} will be overwritten by {name}. Do you confirm?": "Der Charakterbogen von {dest} wird von {name} überschrieben. Trotzdem weitermachen?",
  "Do you want to save the sheet of {name}?": "Möchtest du den Charakterbogen von {name} speichern?",
  "Welcome": "Willkommen",
  "Hirelings": "Mietlinge",
  "Recruit hireling...": "Hilfe anheuern...",
  "Wages/day": "Lohn/Tag",
  "Dismiss": "Entlassen",
  "The hireling of {desc} {name} will be dismissed. Do you confirm?": "Der Mietling von {desc} {name} wird entlassen. Trotzdem weitermachen?",
  "Clear": "Leeren",
  "Export": "Exportieren",
  "{name} is now copied to clipboard.": "{name} ist jetzt in die Zwischenablage kopiert.",
  "Import...": "Importieren...",
  "Paste your character here...": "Füge deinen Charakter hier ein...",
  "Drop item here, in your bank.": "Lege den Gegenstand hier in deiner Bank ab.",
  "Advancement...": "Verbesserungen...",
  "Level up": "Stufenanstieg",
  "{name} prepares advancement...": "{name} befindet sich im Stufenanstieg...",
  "{name} will move from the {current} level to the {next} level.": "{name} steigt von der {current} Stufe zur {next} Stufe auf.",
  "Short rest...": "Kurze Rast...",
  "Short rest": "Kurze Rast",
  "Long rest...": "Lange Rast...",
  "Long rest": "Lange Rast",
  "Full rest...": "Komplette Rast...",
  "Full rest": "Komplette Rast",
  "A short rest lasts 1 turn and restore d6+1 HP. Do you confirm?": "Eine kurze Rast dauert 1 Zug und stellt W6+1 TP wiederher. Weitermachen?",
  "A full rest is a week back in safety. It will cost you around 20 pips. Do you confirm?": "Eine komplette Rast dauer eine Woche in Sicherheit. Es kostet dich ungefähr 20 Kerne. Weitermachen?",
  "Current HP is {value}.": "Deine aktuelle TP sind {value}.",
  "You need to eat and sleep for a watch to do a long rest. All HP restored. Restore d6 to each attributes if HP was at max. Do you confirm?": "Du musst essen und schlafen, um während einer Wache eine lange Rast zu machen. Alle TP werden wiederhergestellt. Stelle W6 in jedem Attribut wieder her, wenn die TP auf Maximum waren. Weitermachen?",
  "Restored all HP.": "Alle TP wiederhergestellt.",
  "Restored all attributes.": "Alle Attribute wiederhergestellt.",
  "Restored some attributes.": "Einige Attribute wiederhergestellt.",
  "Current STR is {value}.": "Deine aktuelle STÄ ist {value}.",
  "Current DEX is {value}.": "Deine aktuelle GES ist {value}.",
  "Current WIL is {value}.": "Deine aktuelle WIL ist {value}.",
  "Do not forget to remove the conditions corresponding to this rest.": "Vergiss nicht Zustände zu entfernen welche nach dieser Rast aufgelöst werden.",
  "Increase in attributes if d20 is higher than the maximum of the latter.": "Würfel jeweils W20 für STÄ, GES und WIL deiner Maus. Ist des Ergebnis höher als der aktuelle Wert, erhöhe ihn um eins.",
  "If {roll} is greater than your maximum HP, then your HP is the sum of the dice. Otherwise they increase by one.": "Wenn {roll} größer ist als deine maximalen TP, erhöhe sie auf dieses Ergebnis. Ansonsten erhöhe sie um 1.",
  "Roll {formula} is {roll}. Max {attr} is {value}.": "{formula} ergibt {roll}. Das Maximum von {attr} ist {value}.",
  "Increase {attr} to {result}.": "Erhöhe {attr} auf {result}.",
  "{attr} will not increase.": "{attr} wird nicht erhöht.",
  "You're encumbered. You cannot run, and makes all saves with disadvantage.": "Du bist belastet. Du kannst nicht rennen und legst alle Proben mit Nachteil ab.",
  "Condolence, your mouse has passed away.": "Herzliches Beileid, deine Maus ist leider verstorben.",
  "Your mouse cannot move.": "Deine Maus kann sich nicht bewegen.",
  "Your mouse is succumbing to madness.": "Deine Maus ist dem Wahnsinn verfallen.",
  "Preferences...": "Vorlieben...",
  "Application font": "Schriftart der Anwendung",
  "3D dices from Anton Natarov": "3D Würfel von Anton Natarov",
  "Welcome, History and Hirelings background from": "Hintergrund von Willkommens-, Verlaufs- und Mietlingshintergrund von",
  "App": "App",
  "Mausritter Sheet folder is {folder}": "Mausritter Charakterbogen Ordner ist {folder}",
  "level {n}": "Stufe {n}",
  "A small custom item.": "Ein kleiner eigener Gegenstand.",
  "A tall custom item.": "Ein hoher eigener Gegenstand.",
  "A wide custom item.": "Ein breiter eigener Gegenstand.",
  "If you leave before saving, your changes will be lost.": "Wenn du diese Seite ohne speichern verlässt, sind deine Veränderungen verloren!",
  "Table": "Tisch",
  "None": "Keine",
  "Damages": "Schaden",
  "Please create or load your mouse.": "Bitte erstelle oder lade deine Maus."
}