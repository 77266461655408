{
  "Fireball": "Feuerball",
  "Heal": "Heilung",
  "Magic Missile": "Magisches Geschoss",
  "Fear": "Furcht",
  "Darkness": "Dunkelheit",
  "Restore": "Genesung",
  "Be Understood": "Verständnis",
  "Ghost Beetle": "Geisterkäfer",
  "Light": "Licht",
  "Invisible Ring": "Unsichtbarer Ring",
  "Knock": "Klopfen",
  "Grease": "Schmieren",
  "Grow": "Wachstum",
  "Invisibility": "Unsichtbarkeit",
  "Catnip": "Katzenminze"
}