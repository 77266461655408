{
  "Star": "Stern",
  "Brave": "Tapfer",
  "Reckless": "Leichtsinnig",
  "Wheel": "Rad",
  "Industrious": "Fleißig",
  "Unimaginative": "Fantasielos",
  "Acorn": "Eichel",
  "Inquisitive": "Wissbegierig",
  "Stubborn": "Stur",
  "Storm": "Sturm",
  "Generous": "Großzügig",
  "Wrathful": "Jähzornig",
  "Moon": "Mond",
  "Wise": "Klug",
  "Mysterious": "Geheimnisvoll",
  "Mother": "Mutter",
  "Nurturing": "Fürsorglich",
  "Worrying": "Besorgt",
  "Chocolate": "Schokoladenbraun",
  "Black": "Schwarz",
  "White": "Weiß",
  "Tan": "Hellbraun",
  "Grey": "Grau",
  "Blue": "Blau",
  "Solid": "Einfarbig",
  "Brindle": "Scheckig",
  "Patchy": "Gefleckt",
  "Banded": "Gestreift",
  "Marbled": "Marmoriert",
  "Flecked": "Gesprenkelt",
  "Scarred body": "Vernarbter Körper",
  "Corpulent body": "Beleibter Körper",
  "Skeletal body": "Magerer Körper",
  "Willowy body": "Gertenschlank",
  "Tiny body": "Kleiner Körper",
  "Massive body": "Kräftiger Körper",
  "War paint": "Kriegsbemalung",
  "Foreign clothes": "Fremdländisch gekleidet",
  "Elegant clothes": "Elegant gekleidet",
  "Patched clothes": "Geflickte Kleidung",
  "Fashionable clothes": "Modisch gekleidet",
  "Unwashed clothes": "Ungewaschene Kleidung",
  "Missing ear": "Fehlendes Ohr",
  "Lumpy face": "Zerfurchtes Gesicht",
  "Beautiful face": "Hübsches Gesicht",
  "Round face": "Rundliches Gesicht",
  "Delicate face": "Zartes Gesicht",
  "Elongated face": "Längliches Gesicht",
  "Groomed fur": "Gepflegtes Fell",
  "Dreadlocks": "Rastalocken",
  "Dyed fur": "Gefärbtes Fell",
  "Shaved fur": "Rasiertes Fell",
  "Frizzy fur": "Krauses Fell",
  "Silky fur": "Glänzendes Fell",
  "Night black eyes": "Nachtschwarze Augen",
  "Eye patch": "Augenklappe",
  "Blood red eyes": "Blutrote Augen",
  "Wise eyes": "Kluge Augen",
  "Sharp eyes": "Wachsame Augen",
  "Luminous eyes": "Leuchtende Augen",
  "Cropped tail": "Abgeschnittener Schwanz",
  "Whip-like tail": "Peitschenartiger Schwanz",
  "Tufted tail": "Buschiger Schwanz",
  "Stubby tail": "Stummelschwanz",
  "Prehensile tail": "Greifschwanz",
  "Curly tail": "Ringelschwanz"
}