{
  "Languages": "Langues",
  "Welcome to Mausritter Sheet!": "Bienvenue sur la fiche de personnage Mausritter !",
  "under licence": "sous licence",
  "Items": "Objets",
  "Conditions": "Conditions",
  "Birthsign": "Signe de naissance",
  "Coat color": "Couleur du pelage",
  "Coat pattern": "Motif du pelage",
  "Color": "Couleur",
  "Pattern": "Motif",
  "Main paw": "Patte directrice",
  "Body": "Corps",
  "Off paw": "Patte opposée",
  "Carried: Ready to use.": "Tenu : Prêt à l'usage.",
  "Worn: Quick to ready.": "Porté : Rapide à saisir.",
  "Pack: Takes time to ready. During combat, requires an action to retrieve.": "Sac : Prend du temps à sortir. En combat, une action pour retrouver un objet.",
  "Name": "Nom",
  "Background": "Passé",
  "Coat": "Pelage",
  "Look": "Apparence",
  "STR": "FOR",
  "DEX": "DEX",
  "WIL": "VOL",
  "STR ⇄ DEX": "FOR ⇄ DEX",
  "STR ⇄ WIL": "FOR ⇄ VOL",
  "DEX ⇄ WIL": "DEX ⇄ VOL",
  "No swap.": "Pas d'échange.",
  "{price}p": "{price} p",
  "HP": "PV",
  "Max": "Max",
  "Current": "Courant",
  "Inventory": "Inventaire",
  "Level": "Niveau",
  "XP": "PX",
  "Grit": "Cran",
  "Ignore a number of conditions equal to your Grit.": "Ignorer autant de conditions que ton score de Cran.",
  "Bank": "Banque",
  "Pips": "Pépins",
  "Roll:": "Tirage :",
  "Roll: ": "Tirage : ",
  "About": "À Propos",
  "This application is an independent production by Daniel Coquette and is not affiliated with Losing Games. It is published under the Mausritter Third Party Licence.": "Cette application est une production indépendante écrite par Daniel Coquette et n'est pas affiliée avec « Losing Games ». Elle est publiée sous la license « Mausritter Third Party Licence ».",
  "Mausritter is copyright Losing Games.": "Mausritter est copyright Losing Games.",
  "This application is copyright Daniel Coquette.": "Cette application est copyright Daniel Coquette.",
  "Sheet and drawer backgrounds from DevianArt under license": "Images de fond de fiche et tiroir par DevianArt sous licence",
  "Close": "Fermer",
  "Neutral": "Neutre",
  "With advantage": "Avec avantage",
  "With disadvantage": "Avec désavantage",
  "Need {score} or lower, roll {roll}.": "Besoin de faire {score} ou moins, lancé {roll}.",
  "Need upper than {upper}, roll {roll}.": "Besoin de dépasser {upper}, lancé {roll}.",
  "Create a new character...": "Créer un nouveau personnage…",
  "Load": "Charger",
  "Save": "Enregistrer",
  "Saves": "Les Sauvegardes",
  "Delete": "Supprimer",
  "Empty": "Vide",
  "SUCCESS": "SUCCÈS",
  "FAILED": "ÉCHEC",
  "Clear:": "Retirer :",
  "STR save": "Sauvegarde de FOR",
  "DEX save": "Sauvegarde de DEX",
  "WIL save": "Sauvegarde de VOL",
  "Choose...": "Choisissez…",
  "Improvised": "Improvisé",
  "Weapon": "Arme",
  "Needle": "Aiguille",
  "Dagger": "Dague",
  "Axe": "Hache",
  "Sword": "Épée",
  "Mace": "Masse",
  "Hammer": "Marteau",
  "Warhammer": "Marteau de guerre",
  "Spear": "Lance",
  "Hookarm": "Lance-Crochet",
  "Bow": "Arc",
  "Arrows": "Flèches",
  "Sling": "Fronde",
  "Stones": "Pierres",
  "Heavy Armour": "Armure Lourde",
  "Light Armour": "Armure Légère",
  "Torches": "Torches",
  "Lantern": "Lanterne",
  "Electric Lantern": "Lanterne Électrique",
  "Pip Purse": "Bourse",
  "Rations": "Rations",
  "Spell": "Sort",
  "Item": "Objet",
  "A custom item.": "Un objet personnalisé.",
  "The sheet of {name} will be erased. Do you confirm?": "Confirmes-tu l'effacement de la fiche de « {name} » ?",
  "Confirm": "Confirmation",
  "Yes": "Oui",
  "No": "Non",
  "OK": "OK",
  "Def": "Def",
  "Items and conditions drawer.": "Tiroir à objets et conditions",
  "Roll {dice}": "Lancer {dice}",
  "{name} prepares for adventure...": "« {name} » se prépare pour l'aventure…",
  "You may swap any two attributes.": "Tu peux intervertir deux des attributs.",
  "You may choose a weapon below:": "Choisissez une arme ci-dessous :",
  "You can take one item below:": "Tu peux prendre un des objet ci-dessous :",
  "Item from inherited your background of «{background}»": "Objets hérités de ton passé de « {background} »",
  "Your mouse must pay a fee of 1% of the value when retrieving the stored pips or items.": "À chaque retrait, ta souris doit payer des frais à hauteur de 1 % de la valeur des pépins ou objets retirés.",
  "Retrieving {name} from your bank will cost you {fee} pips. Confirm?": "Retirer « {name} » de ta banque te coutera {fee} pépins. Confirmes-tu le retrait ?",
  "You do need to have {fee} pips to retrieve {name}!": "Tu as besoin de {fee} pépins pour retirer « {name} » !",
  "One paw": "Une patte",
  "Both paws": "Deux pattes",
  "History": "Historique",
  "Manage your characters.": "Gérer tes personnages.",
  "The sheet of {dest} will be overwritten by {name}. Do you confirm?": "Confirmes-tu le remplacement de la fiche de « {dest} » par celle de « {name} » ?",
  "Do you want to save the sheet of {name}?": "Voulez-vous enregistrer la fiche de « {name} » ?",
  "Welcome": "Bienvenue",
  "Hirelings": "Employés",
  "Recruit hireling...": "Engager un employé…",
  "Wages/day": "Salaire / jour",
  "Dismiss": "Renvoyer",
  "The hireling of {desc} {name} will be dismissed. Do you confirm?": "Confirmes-tu le renvoi du « {desc} {name} » ?",
  "Clear": "Effacer",
  "Export": "Exporter",
  "{name} is now copied to clipboard.": "« {name} » a été copié dans le presse-papier.",
  "Import...": "Importer…",
  "Paste your character here...": "Coller le personnage ici…",
  "Drop item here, in your bank.": "Déposer ici les objets à mettre en banque.",
  "Advancement...": "Monter de niveau…",
  "Level up": "Monter de niveau",
  "{name} prepares advancement...": "« {name} » se prépare à monter de niveau…",
  "{name} will move from the {current} level to the {next} level.": "« {name} » passera du niveau {current} au niveau {next}.",
  "Short rest...": "Repos court…",
  "Short rest": "Repos court",
  "Long rest...": "Repos long…",
  "Long rest": "Repos long",
  "Full rest...": "Repos complet…",
  "Full rest": "Repos complet",
  "A short rest lasts 1 turn and restore d6+1 HP. Do you confirm?": "Un repos court dure 1 tour et restaure d6+1 PV. Confirmes-tu ?",
  "A full rest is a week back in safety. It will cost you around 20 pips. Do you confirm?": "Le repos complet nécessite d'être une semaine dans un lieu sûr. Cela coûte 20 pépins. Confirmes-tu ?",
  "Current HP is {value}.": "PV courant : {value}.",
  "You need to eat and sleep for a watch to do a long rest. All HP restored. Restore d6 to each attributes if HP was at max. Do you confirm?": "Tu dois manger et dormir pendant une garde. Restaure tous les PV. Si tes PV sont au maximum, restaure aussi d6 à chaque attribut. Confirmes-tu?",
  "Restored all HP.": "Tous tes PV ont été restaurés.",
  "Restored all attributes.": "Tous tes attributs ont été restaurés.",
  "Restored some attributes.": "Tes Attributs ont été restaurés.",
  "Current STR is {value}.": "FOR en cours : {value}.",
  "Current DEX is {value}.": "DEX en cours : {value}.",
  "Current WIL is {value}.": "VOL en cours : {value}.",
  "Do not forget to remove the conditions corresponding to this rest.": "N'oublies pas de retirer les conditions correspondant à ce repos.",
  "Increase in attributes if d20 is higher than the maximum of the latter.": "Augmentation des attributs si d20 est supérieur au maximum de celui-ci.",
  "If {roll} is greater than your maximum HP, then your HP is the sum of the dice. Otherwise they increase by one.": "Si {roll} est supérieur au maximum de tes PV, alors tes PV sont la somme des dés. Sinon ils augmentent d'un point.",
  "Roll {formula} is {roll}. Max {attr} is {value}.": "Tirage de {formula} donne {roll}. Le max des PV est {hp}.",
  "Increase {attr} to {result}.": "Augmentation de {attr} à {result}.",
  "{attr} will not increase.": "Pas d'augmentation de {attr}",
  "You're encumbered. You cannot run, and makes all saves with disadvantage.": "Tu es encombré, tu ne peux plus courir, et tu fais tous tes jets de sauvegarde avec un désavantage.",
  "Condolence, your mouse has passed away.": "Condoléance, ta souris est décédée.",
  "Your mouse cannot move.": "Ta souris ne peut plus bouger.",
  "Your mouse is succumbing to madness.": "Ta souris succombe à l'atonie.",
  "Preferences...": "Préférences…",
  "Application font": "Police de l'application",
  "3D dices from Anton Natarov": "Dés en 3D par Anton Natarov :",
  "Welcome, History and Hirelings background from": "Fond de l'accueil, de l'historique et des employés par",
  "App": "App",
  "Mausritter Sheet folder is {folder}": "Le dossier Mausritter est {folder}",
  "level {n}": "niveau {n}",
  "A small custom item.": "Un petit objet",
  "A tall custom item.": "Un grand objet ",
  "A wide custom item.": "Un objet large",
  "If you leave before saving, your changes will be lost.": "Si vous quittez avant de sauvegarder, vos modifications seront perdues.",
  "Table": "Table",
  "None": "Aucun(e)",
  "Damages": "Dégâts",
  "Please create or load your mouse.": "Créez ou chargez votre souris."
}