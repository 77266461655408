{
  "Test subject": "Versuchsobjekt",
  "Lead coat": "Bleischürze",
  "Magic missile": "Magisches Geschoss",
  "Kitchen forager": "Versorger:in",
  "Shield & jerkin": "Schild & Lederwams",
  "Cookpots": "Kochtöpfe",
  "Cage dweller": "Käfigmaus",
  "Bottle of milk": "Milchflasche",
  "Hedge witch": "Heckenhexe:r",
  "Incense stick": "Räucherstäbchen",
  "Leatherworker": "Lederarbeiter:in",
  "Shears": "Schere",
  "Street tough": "Straßenkämpfer:in",
  "Flask of coffee": "Feldflasche mit Kaffee",
  "Mendicant priest": "Bettelpriester:in",
  "Holy symbol": "Heiliges Symbol",
  "Beetleherd": "Käferhirt:in",
  "Pole, 6\"": "Stange, 15 cm",
  "Ale brewer": "Bierbrauer:in",
  "Small barrel of ale": "Kleines Bierfass",
  "Fishermouse": "Fischermaus",
  "Net": "Netz",
  "Blacksmith": "Schmied:in",
  "Metal file": "Metallfeile",
  "Wireworker": "Drahtzieher:in",
  "Wire, spool": "Draht, Spule",
  "Woodcutter": "Holzfäller:in",
  "Twine, roll": "Zwirn, Rolle",
  "Bat cultist": "Fledermauskultist:in",
  "Bag of bat teeth": "Beutel mit Fledermauszähnen",
  "Tin miner": "Zinngrubenmaus",
  "Pickaxe": "Spitzhacke",
  "Trash collector": "Schrottsammler:in",
  "Trashhook": "Haken",
  "Mirror": "Spiegel",
  "Wall rover": "Fassadenläufer:in",
  "Fishhook": "Fischhaken",
  "Thread, spool": "Schnur, Rolle",
  "Merchant": "Händler:in",
  "20p IOU from a noblemouse": "20 Kerne, geliehen von adliger Maus",
  "Raft crew": "Flößer:in",
  "Wooden spikes": "Holzpflöcke",
  "Worm wrangler": "Wurmhirt:in",
  "Soap": "Seife",
  "Sparrow rider": "Spatzenreiter:in",
  "Goggles": "Schutzbrille",
  "Sewer guide": "Kanalführer:in",
  "Prison guard": "Gefängniswache",
  "Chain, 6\"": "Kette, 15 cm",
  "Fungus farmer": "Pilzfarmer:in",
  "Dried mushroom": "Getrockneter Pilz",
  "Spore mask": "Atemmaske",
  "Dam builder": "Dammarbeiter:in",
  "Shovel": "Schaufel",
  "Cartographer": "Kartograph:in",
  "Quill & ink": "Federkiel & Tinte",
  "Compass": "Kompass",
  "Trap thief": "Fallenplünderer:in",
  "Block of cheese": "Stück Käse",
  "Glue": "Kleber",
  "Vagabond": "Vagabund:in",
  "Tent": "Zelt",
  "Treasure map, dubious": "Fragwürdige Schatzkarte",
  "Grain farmer": "Getreidefarmer:in",
  "Whistle": "Pfeife",
  "Message runner": "Botenmaus",
  "Bedroll": "Schlafsack",
  "Documents, sealed": "Dokumente, versiegelt",
  "Troubadour": "Troubadour:in",
  "Musical instrument": "Musikinstrument",
  "Disguise kit": "Verkleidungsausrüstung",
  "Gambler": "Glücksspieler:in",
  "Set of loaded dice": "Satz gezinkter Würfel",
  "Sap tapper": "Harzsammler:in",
  "Bucket": "Eimer",
  "Bee keeper": "Imker:in",
  "Jar of honey": "Honigtopf",
  "Librarian": "Bibliothekar:in",
  "Scrap of obscure book": "Teil eines seltsamen Buches",
  "Pauper noblemouse": "Verarmte Adelsmaus",
  "Felt hat": "Filzhut",
  "Perfume": "Parfüm"
}