{
  "Test subject": "Sujet d’expériences",
  "Lead coat": "Plaque de plomb",
  "Magic missile": "Projectile magique",
  "Kitchen forager": "Commise de cuisine",
  "Shield & jerkin": "Blouson et bouclier",
  "Cookpots": "Casseroles",
  "Cage dweller": "Souris domestique",
  "Bottle of milk": "Bouteille de lait",
  "Hedge witch": "Sorcière des buissons",
  "Incense stick": "Bâton d’encens",
  "Leatherworker": "Maroquinière",
  "Shears": "Ciseaux",
  "Street tough": "Dure à cuire",
  "Flask of coffee": "Flasque de café",
  "Mendicant priest": "Prêtresse mendiante",
  "Holy symbol": "Symbole sacré",
  "Beetleherd": "Scarabergère",
  "Pole, 6\"": "Perche, 15 cm",
  "Ale brewer": "Brasseuse",
  "Small barrel of ale": "Petit tonneau de bière",
  "Fishermouse": "Pêcheuse",
  "Net": "Filet",
  "Blacksmith": "Forgeronne",
  "Metal file": "Lime métallique",
  "Wireworker": "Câbleuse",
  "Wire, spool": "Câble, bobine",
  "Woodcutter": "Bûcheronne",
  "Twine, roll": "Ficelle, pelote",
  "Bat cultist": "Adepte des Chauves-Souris",
  "Bag of bat teeth": "Dents de chauves-souris",
  "Tin miner": "Mineuse d’étain",
  "Pickaxe": "Pioche",
  "Trash collector": "Ramasseuse d’ordures",
  "Trashhook": "Crochet à ordures",
  "Mirror": "Miroir",
  "Wall rover": "Rôdeuse des murs",
  "Fishhook": "Hameçon",
  "Thread, spool": "Fil, bobine",
  "Merchant": "Marchande",
  "20p IOU from a noblemouse": "Créance de 20p d’un noble",
  "Raft crew": "Sapeuse",
  "Wooden spikes": "Pieux en bois",
  "Worm wrangler": "Dresseuse de vers",
  "Soap": "Savon",
  "Sparrow rider": "Chevaucheuse de moineau",
  "Goggles": "Lunettes",
  "Sewer guide": "Guide des égouts",
  "Prison guard": "Gardienne de prison",
  "Chain, 6\"": "Chaînes, 15 cm",
  "Fungus farmer": "Cultivatrice de champignons",
  "Dried mushroom": "Cèpes séchés",
  "Spore mask": "Masque anti-spores",
  "Dam builder": "Constructrice de barrages",
  "Shovel": "Pelle",
  "Cartographer": "Cartographe",
  "Quill & ink": "Encre et plume",
  "Compass": "Boussole",
  "Trap thief": "Voleuse à pièges",
  "Block of cheese": "Morceau de fromage",
  "Glue": "Colle",
  "Vagabond": "Vagabonde",
  "Tent": "Tente",
  "Treasure map, dubious": "Carte au trésor, douteuse",
  "Grain farmer": "Cultivatrice de céréales",
  "Whistle": "Sifflet",
  "Message runner": "Messagère",
  "Bedroll": "Sac de couchage",
  "Documents, sealed": "Documents, scellés",
  "Troubadour": "Troubadour",
  "Musical instrument": "Instrument de musique",
  "Disguise kit": "Kit de déguisement",
  "Gambler": "Joueuse",
  "Set of loaded dice": "Jeu de dés pipés",
  "Sap tapper": "Tireuse de sève",
  "Bucket": "Seau",
  "Bee keeper": "Apicultrice",
  "Jar of honey": "Pot de miel",
  "Librarian": "Bibliothécaire",
  "Scrap of obscure book": "Morceaux d’un livre obscur",
  "Pauper noblemouse": "Noble sans-le-sou",
  "Felt hat": "Chapeau en feutrine",
  "Perfume": "Parfum"
}