{
  "Loyal beetle": "Escarabajo leal",
  "Drunken torchbearer": "Portaantorchas ebrio",
  "Pack rat": "Cargador",
  "torchbearer": "Portaantorcha",
  "Labourer": "Trabajador",
  "Tunnel digger": "Excavador",
  "Armourer/blacksmith": "Armero/herrero",
  "Local guide": "Guía local",
  "Mouse-at-arms": "Soldado",
  "Scholar": "Erudito",
  "Knight": "Caballero",
  "Interpreter": "Intérprete"
}