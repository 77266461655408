{
  "Loyal beetle": "Treuer Käfer",
  "Drunken torchbearer": "Betrunkener Fackelträger",
  "Pack rat": "Packratte",
  "torchbearer": "Fackelträger:in",
  "Labourer": "Arbeiter:in",
  "Tunnel digger": "Tunnelwühler:in",
  "Armourer/blacksmith": "Schmied:in",
  "Local guide": "Ortskundige:r Führer:in",
  "Mouse-at-arms": "Waffenmaus",
  "Scholar": "Gelehrte:r",
  "Knight": "Ritter:in",
  "Interpreter": "Dolmetscher:in"
}