{
  "Languages": "Языки",
  "Welcome to Mausritter Sheet!": "Добро пожаловать в Mausritter Sheet!",
  "under licence": "по лицензии",
  "Items": "Предметы",
  "Conditions": "Состояния",
  "Birthsign": "Знак рождения",
  "Coat color": "Цвет окраса",
  "Coat pattern": "Узор окраса",
  "Color": "Окрас",
  "Pattern": "Узор",
  "Main paw": "Основная лапа",
  "Body": "Тело",
  "Off paw": "Вторая лапа",
  "Carried: Ready to use.": "В лапах: можно использовать",
  "Worn: Quick to ready.": "Надетое: можно быстро подготовить",
  "Pack: Takes time to ready. During combat, requires an action to retrieve.": "В мешке: чтобы подготовить, нужно время. Чтобы достать во время боя, требуется действие",
  "Name": "Имя",
  "Background": "Происхождение",
  "Coat": "Окрас",
  "Look": "Физическая особенность",
  "STR": "СИЛ",
  "DEX": "ЛОВ",
  "WIL": "ВОЛ",
  "STR ⇄ DEX": "СИЛ ⇄ ЛОВ",
  "STR ⇄ WIL": "СИЛ ⇄ ВОЛ",
  "DEX ⇄ WIL": "ЛОВ ⇄ ВОЛ",
  "No swap.": "Не менять.",
  "{price}p": "{price} з",
  "HP": "ОЗ",
  "Max": "Макс",
  "Current": "Текущее",
  "Inventory": "Снаряжение",
  "Level": "Уровень",
  "XP": "ПО",
  "Grit": "Выдержка",
  "Ignore a number of conditions equal to your Grit.": "Игнорируй количество состояний, равное твоей выдержке",
  "Bank": "Банк",
  "Pips": "Зёрнышки",
  "Roll:": "Бросок:",
  "Roll: ": "Бросок: ",
  "About": "О",
  "This application is an independent production by Daniel Coquette and is not affiliated with Losing Games. It is published under the Mausritter Third Party Licence.": "Это приложение является независимым продуктом Дэниела Кокетта и не связано с Losing Games. Оно опубликовано по лицензии третьей стороны Mausritter.",
  "Mausritter is copyright Losing Games.": "Авторские права на Mausritter принадлежат Losing Games.",
  "This application is copyright Daniel Coquette.": "Авторские права на это приложение принадлежат Дэниелу Кокетту.",
  "Sheet and drawer backgrounds from DevianArt under license": "Изображения фона и вкладок взяты с сайта DevianArt по лицензии",
  "Close": "Закрыть",
  "Neutral": "Без модификатора",
  "With advantage": "С преимуществом",
  "With disadvantage": "С помехой",
  "Need {score} or lower, roll {roll}.": "Требуется {score} или ниже, выпало {roll}.",
  "Need upper than {upper}, roll {roll}.": "Требуется более {upper}, выпало {roll}..",
  "Create a new character...": "Создать нового персонажа...",
  "Load": "Загрузить",
  "Save": "Сохранить",
  "Saves": "Сохранения",
  "Delete": "Удалить",
  "Empty": "Пусто",
  "SUCCESS": "УСПЕХ",
  "FAILED": "ПРОВАЛ",
  "Clear:": "Очистить:",
  "STR save": "Спасбросок силы",
  "DEX save": "Спасбросок ловкости",
  "WIL save": "Спасбросок воли",
  "Choose...": "Выберите...",
  "Weapon": "Оружие",
  "Improvised": "Импровизированное",
  "Needle": "Игла",
  "Dagger": "Кинжал",
  "Axe": "Топор",
  "Sword": "Меч",
  "Mace": "Булава",
  "Hammer": "Молот",
  "Warhammer": "Боевой молот",
  "Spear": "Копьё",
  "Hookarm": "Багор",
  "Bow": "Лук",
  "Arrows": "Стрелы",
  "Sling": "Праща",
  "Stones": "Камни",
  "Light Armour": "Лёгкая броня",
  "Heavy Armour": "Тяжёлая броня",
  "Torches": "Связка факелов",
  "Lantern": "Лампа",
  "Electric Lantern": "Электрический фонарь",
  "Pip Purse": "Кошелёк для зёрнышек",
  "Rations": "Пайки",
  "Spell": "Заклинание",
  "Item": "Предмет",
  "A custom item.": "Пользовательский предмет.",
  "The sheet of {name} will be erased. Do you confirm?": "Лист персонажа «{name}» будет удалён. Продолжить?",
  "Confirm": "Подтвердить",
  "Yes": "Да",
  "No": "Нет",
  "OK": "ОК",
  "Def": "ЗАЩ",
  "Items and conditions drawer.": "Gegenstand und Zustand Schublade",
  "Roll {dice}": "Бросьте {dice}",
  "{name} prepares for adventure...": "{name} готовится к приключениям...",
  "You may swap any two attributes.": "Вы можете поменять местами любые два значения характеристик.",
  "You may choose a weapon below:": "Вы можете выбрать оружие ниже:",
  "You can take one item below:": "Вы можете выбрать один предмет ниже:",
  "Item from inherited your background of «{background}»": "Вы «{background}», поэтому у вас при себе:",
  "Your mouse must pay a fee of 1% of the value when retrieving the stored pips or items.": "При возврате зёрнышек и предметов из банка ваша мышь должна заплатить комиссию в размере 1% от их стоимости.",
  "Retrieving {name} from your bank will cost you {fee} pips. Confirm?": "Возврат {name} из банка обойдется вам в комиссию в размере {fee}. Продолжить?",
  "You do need to have {fee} pips to retrieve {name}!": "На счету банка должно быть минимум {fee}з, чтобы вернуть {name}!",
  "One paw": "Одной лапой",
  "Both paws": "Двумя лапами",
  "History": "История",
  "Manage your characters.": "Управляйте своими персонажами.",
  "The sheet of {dest} will be overwritten by {name}. Do you confirm?": "Лист персонажа «{dest}» будет переписан на персонажа «{name}». Продолжить?",
  "Do you want to save the sheet of {name}?": "Вы хотите сохранить лист персонажа «{name}»?",
  "Welcome": "Добро пожаловать",
  "Hirelings": "Подёнщики",
  "Recruit hireling...": "Нанять подёнщика...",
  "Wages/day": "Плата в день",
  "Dismiss": "Уволить",
  "The hireling of {desc} {name} will be dismissed. Do you confirm?": "Подёнщик {desc} {name} будет уволен. Вы уверены?",
  "Clear": "Очистить",
  "Export": "Экспорт",
  "{name} is now copied to clipboard.": "{name} было скопировано в буфер обмена.",
  "Import...": "Импорт...",
  "Paste your character here...": "Вставьте вашего персонажа сюда...",
  "Drop item here, in your bank.": "Перетащите предмет сюда, чтобы оставить в банке.",
  "Advancement...": "Развитие...",
  "Level up": "Повышение уровня",
  "{name} prepares advancement...": "{name} ожидает развитие...",
  "{name} will move from the {current} level to the {next} level.": "{name} переходит с {current} уровня на {next} уровень.",
  "Short rest...": "Передышка...",
  "Short rest": "Передышка",
  "Long rest...": "Длительный отдых...",
  "Long rest": "Длительный отдых",
  "Full rest...": "Полный отдых...",
  "Full rest": "Полный отдых",
  "A short rest lasts 1 turn and restore d6+1 HP. Do you confirm?": "Передышка займёт один ход. Глоток воды и несколько минут отдыха восстановят d6+1 ОЗ. Продолжить?",
  "A full rest is a week back in safety. It will cost you around 20 pips. Do you confirm?": "Полный отдых займёт неделю, которую нужно провести в безопасном месте. Неделя питания и проживания в поселении обычно стоит 20 з. Продолжить?",
  "Current HP is {value}.": "Текущие ОЗ: {value}.",
  "You need to eat and sleep for a watch to do a long rest. All HP restored. Restore d6 to each attributes if HP was at max. Do you confirm?": "Длительный отдых занимает одну вахту. Еда и сон восстановят все очки ОЗ. Если ОЗ полные, то брось d6, чтобы восстановить характеристики. Продолжить?",
  "Restored all HP.": "Вся ОЗ восстановлена.",
  "Restored all attributes.": "Все характеристики восстановлены.",
  "Restored some attributes.": "Некоторые характеристики восстановлены.",
  "Current STR is {value}.": "Текущая СИЛ: {value}.",
  "Current DEX is {value}.": "Текущая ЛОВ: {value}.",
  "Current WIL is {value}.": "Текучая ВОЛ: {value}.",
  "Do not forget to remove the conditions corresponding to this rest.": "Не забудьте убрать состояния, которые этот отдых снимает.",
  "Increase in attributes if d20 is higher than the maximum of the latter.": "Чтобы повысить характеристики своей мыши, бросьте по одному d20 для силы, ловкости и воли. Если результат выше текущего значения характеристики, повысь его на единицу.",
  "If {roll} is greater than your maximum HP, then your HP is the sum of the dice. Otherwise they increase by one.": "Если {roll} больше вашей максимальной ОЗ, то ваша ОЗ равно сумме кубиков. В противном случае ОЗ увеличиваются на единицу.",
  "Roll {formula} is {roll}. Max {attr} is {value}.": "{formula} : {roll}. Максимум {attr} : {value}.",
  "Increase {attr} to {result}.": "Повысьте {attr} до {result}.",
  "{attr} will not increase.": "{attr} не повышена.",
  "You're encumbered. You cannot run, and makes all saves with disadvantage.": "Вы перегружены. Вы не можете бегать и проходите все спасброски с помехой.",
  "Condolence, your mouse has passed away.": "Соболезную вашей утрате, это была хорошая мышь.",
  "Your mouse cannot move.": "Ваша мышь не может двигаться.",
  "Your mouse is succumbing to madness.": "Ваша мышь сходит с ума.",
  "Preferences...": "Настройки...",
  "Application font": "Шрифт текста",
  "3D dices from Anton Natarov": "3D кубики разработаны Антоном Натаровым",
  "Welcome, History and Hirelings background from": "Фоны вкладок «Добро пожаловать», «История» и «Подёнщики» от",
  "App": "App",
  "Mausritter Sheet folder is {folder}": "Папка с листами персонажей Mausritter: {folder}",
  "level {n}": "Уровень {n}",
  "A small custom item.": "Маленький пользовательский предмет.",
  "A tall custom item.": "Высокий пользовательский предмет.",
  "A wide custom item.": "Широкий пользовательский предмет.",
  "If you leave before saving, your changes will be lost.": "Если вы выйдете до сохранения, ваши изменения будут потеряны.",
  "Table": "Стол",
  "None": "Нет",
  "Damages": "Урон",
  "Please create or load your mouse.": "Пожалуйста, создайте или загрузите вашу мышь."
}