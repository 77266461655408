export const SWAP_NONE = 0
export const SWAP_STR_DEX = 1
export const SWAP_STR_WIL = 2
export const SWAP_DEX_WIL = 3

export default {
  SWAP_NONE,
  SWAP_STR_DEX,
  SWAP_STR_WIL,
  SWAP_DEX_WIL
}