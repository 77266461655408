{
  "Test subject": "Подопытный",
  "Lead coat": "Свинцовый фартук",
  "Magic missile": "Магический снаряд",
  "Kitchen forager": "Повар",
  "Shield & jerkin": "Щит и куртка",
  "Cookpots": "Котелки",
  "Cage dweller": "Житель клетки",
  "Bottle of milk": "Бутылка молока",
  "Hedge witch": "Знахарь",
  "Incense stick": "Ароматическая палочка",
  "Leatherworker": "Кожевенник",
  "Shears": "Ножницы",
  "Street tough": "Уличный бандит",
  "Flask of coffee": "Фляжка кофе",
  "Mendicant priest": "Нищий священник",
  "Holy symbol": "Святой символ",
  "Beetleherd": "Пчелопас",
  "Pole, 6\"": " Шест, 6 дюймов",
  "Ale brewer": "Пивовар",
  "Small barrel of ale": "Бочонок эля",
  "Fishermouse": "Рыбак",
  "Net": "Сеть",
  "Blacksmith": "Кузнец",
  "Metal file": "Напильник",
  "Wireworker": "Проволочник",
  "Wire, spool": "Проволока, катушка",
  "Woodcutter": "Резчик по дереву",
  "Twine, roll": " Верёвка, моток",
  "Bat cultist": "Сектант из культа Летучей мыши",
  "Bag of bat teeth": "Мешок зубов летучих мышей",
  "Tin miner": "Оловодобытчик",
  "Pickaxe": "Кирка",
  "Trash collector": "Мусорщик",
  "Trashhook": "Крюк для мусора",
  "Mirror": "Зеркало",
  "Wall rover": "Стенолаз",
  "Fishhook": "Рыболовный крючок",
  "Thread, spool": "Нить, катушка",
  "Merchant": "Торговец",
  "20p IOU from a noblemouse": "Вексель на 20 з. от благородного дома",
  "Raft crew": "Матрос с плота",
  "Wooden spikes": "Деревянные колья",
  "Worm wrangler": "Погонщик червей",
  "Soap": "Мыло",
  "Sparrow rider": "Воробьиный наездник",
  "Goggles": "Защитные очки",
  "Sewer guide": "Проводник по канализации",
  "Prison guard": "Тюремный стражник",
  "Chain, 6\"": "Цепь, 6 дюймов",
  "Fungus farmer": "Фермер-грибник",
  "Dried mushroom": "Сушёные грибы (как пайки)",
  "Spore mask": "Маска от спор",
  "Dam builder": "Строитель плотины",
  "Shovel": "Лопата",
  "Cartographer": "Картограф",
  "Quill & ink": "Перо и чернила",
  "Compass": "Компас",
  "Trap thief": "Грабитель ловушек",
  "Block of cheese": "Кусок сыра",
  "Glue": "Клей",
  "Vagabond": "Бродяга",
  "Tent": "Палатка",
  "Treasure map, dubious": "Карта сокровищ, недостоверная",
  "Grain farmer": "Фермер-зерновод",
  "Whistle": "Свисток",
  "Message runner": "Гонец",
  "Bedroll": "Спальный мешок",
  "Documents, sealed": "Документы, запечатанные",
  "Troubadour": "Трубадур",
  "Musical instrument": "Музыкальный инструмент",
  "Disguise kit": "Гримировальный набор",
  "Gambler": "Азартный игрок",
  "Set of loaded dice": "Шулерские игральные кости",
  "Sap tapper": "Добытчик древесного сока",
  "Bucket": "Ведро",
  "Bee keeper": "Пчеловод",
  "Jar of honey": "Горшок с мёдом",
  "Librarian": "Библиотекарь",
  "Scrap of obscure book": "Обрывок таинственной книги",
  "Pauper noblemouse": "Обнищавший дворянин",
  "Felt hat": "Фетровая шляпа",
  "Perfume": "Духи"
}