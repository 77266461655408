{
  "Test subject": "Sujeto de pruebas",
  "Lead coat": "Chaleco de plomo",
  "Magic missile": "Proyectil mágico",
  "Kitchen forager": "Forager",
  "Shield & jerkin": "Escudo y jubón",
  "Cookpots": "Cacerolas",
  "Cage dweller": "Habitante de las jaulas",
  "Bottle of milk": "Botella de leche",
  "Hedge witch": "Curandero del arbusto",
  "Incense stick": "Incienso",
  "Leatherworker": "Curtidor",
  "Shears": "Tijeras",
  "Street tough": "Ratón callejero",
  "Flask of coffee": "Petaca de café",
  "Mendicant priest": "Sacerdote mendicante",
  "Holy symbol": "Símbolo Sagrado",
  "Beetleherd": "Pastor de bichos",
  "Pole, 6\"": "Pértiga, 15 cm",
  "Ale brewer": "Cervecero",
  "Small barrel of ale": "Barril de cerveza pequeño",
  "Fishermouse": "Pescador",
  "Net": "Red",
  "Blacksmith": "Herrero",
  "Metal file": "Lima de metal",
  "Wireworker": "Cableador",
  "Wire, spool": "Cable, rollo",
  "Woodcutter": "Leñador",
  "Twine, roll": "Cordel, rollo",
  "Bat cultist": "Sectario del Murciélago",
  "Bag of bat teeth": "Bolsa de dientes de murciélago",
  "Tin miner": "Minero de latón",
  "Pickaxe": "Pico",
  "Trash collector": "Recolector de basura",
  "Trashhook": "Gancho para basura",
  "Mirror": "Espejo",
  "Wall rover": "Trepamuros",
  "Fishhook": "Anzuelo",
  "Thread, spool": "Hilo, bobina",
  "Merchant": "Mercader",
  "20p IOU from a noblemouse": "Pagaré de un ratón noble (20p.)",
  "Raft crew": "Balsero",
  "Wooden spikes": "Clavos de madera",
  "Worm wrangler": "Domador de gusanos",
  "Soap": "Jabón",
  "Sparrow rider": "Jinete de gorrión",
  "Goggles": "Gafas protectoras",
  "Sewer guide": "Guía de alcantarillas",
  "Prison guard": "Guarda de prisión",
  "Chain, 6\"": "Cadena",
  "Fungus farmer": "Jardinero de hongos",
  "Dried mushroom": "Hongos secos",
  "Spore mask": "Máscara antiesporas",
  "Dam builder": "Constructor de presas",
  "Shovel": "Pala",
  "Cartographer": "Cartógrafo",
  "Quill & ink": "Pluma y tintero",
  "Compass": "Brújula",
  "Trap thief": "Ladrón trampero",
  "Block of cheese": "Cuña de queso",
  "Glue": "Pegamento",
  "Vagabond": "Vagabundo",
  "Tent": "Tienda",
  "Treasure map, dubious": "Mapa del tesoro, no fiable",
  "Grain farmer": "Agricultor del grano",
  "Whistle": "Silbato",
  "Message runner": "Mensajero",
  "Bedroll": "Saco de dormir",
  "Documents, sealed": "Documentos, sellados",
  "Troubadour": "Trovador",
  "Musical instrument": "Instrumento musical",
  "Disguise kit": "Kit de disfraz",
  "Gambler": "Tahúr",
  "Set of loaded dice": "Juego de dados trucados",
  "Sap tapper": "Extractor de savia",
  "Bucket": "Cubo",
  "Bee keeper": "Apicultor",
  "Jar of honey": "Tarro de miel",
  "Librarian": "Bibliotecario",
  "Scrap of obscure book": "Fragmento de libro oscuro",
  "Pauper noblemouse": "Noble empobrecido",
  "Felt hat": "Sombrero de fieltro",
  "Perfume": "Perfume"
}