{
  "Star": "Étoile",
  "Brave": "Courageuse",
  "Reckless": "Imprudente",
  "Wheel": "Roue",
  "Industrious": "Travailleuse",
  "Unimaginative": "Sans imagination",
  "Acorn": "Gland",
  "Inquisitive": "Indiscrète",
  "Stubborn": "Têtue",
  "Storm": "Tempête",
  "Generous": "Généreuse",
  "Wrathful": "Irascible",
  "Moon": "Lune",
  "Wise": "Sage",
  "Mysterious": "Mystérieuse",
  "Mother": "Mère",
  "Nurturing": "Réconfortante",
  "Worrying": "Inquiète",
  "Chocolate": "Chocolat",
  "Black": "Noir",
  "White": "Blanc",
  "Tan": "Brun",
  "Grey": "Gris",
  "Blue": "Bleu",
  "Solid": "Uni",
  "Brindle": "Rayé",
  "Patchy": "Tacheté",
  "Banded": "Avec des bandes",
  "Marbled": "Marbré",
  "Flecked": "Moucheté",
  "Scarred body": "Cicatrices",
  "Corpulent body": "Corpulent",
  "Skeletal body": "Squelettique",
  "Willowy body": "Filiforme",
  "Tiny body": "Petit",
  "Massive body": "Massif",
  "War paint": "Peintures de guerre",
  "Foreign clothes": "Habits étrangers",
  "Elegant clothes": "Habits élégants",
  "Patched clothes": "Habits rapiécés",
  "Fashionable clothes": "Habits à la mode",
  "Unwashed clothes": "Habits sales",
  "Missing ear": "Oreille manquante",
  "Lumpy face": "Visage grumeleux",
  "Beautiful face": "Joli visage",
  "Round face": "Visage rond",
  "Delicate face": "Visage délicat",
  "Elongated face": "Visage allongé",
  "Groomed fur": "Fourrure entretenue",
  "Dreadlocks": "Dreadlocks",
  "Dyed fur": "Fourrure teinte",
  "Shaved fur": "Fourrure rasée",
  "Frizzy fur": "Fourrure frisée",
  "Silky fur": "Fourrure soyeuse",
  "Night black eyes": "Yeux d’ébène",
  "Eye patch": "Cache-œil",
  "Blood red eyes": "Yeux rouges",
  "Wise eyes": "Regard sage",
  "Sharp eyes": "Regard vif",
  "Luminous eyes": "Regard lumineux",
  "Cropped tail": "Queue coupée",
  "Whip-like tail": "Queue telle un fouet",
  "Tufted tail": "Queue touffue",
  "Stubby tail": "Queue épaisse",
  "Prehensile tail": "Queue préhensile",
  "Curly tail": "Queue tordue"
}