{
  "Loyal beetle": "Loyal beetle",
  "Drunken torchbearer": "Drunken torchbearer",
  "Pack rat": "Pack rat",
  "torchbearer": "Torchbearer",
  "Labourer": "Labourer",
  "Tunnel digger": "Tunnel digger",
  "Armourer/blacksmith": "Armourer/blacksmith",
  "Local guide": "Local guide",
  "Mouse-at-arms": "Mouse-at-arms",
  "Scholar": "Scholar",
  "Knight": "Knight",
  "Interpreter": "Interpreter"
}