{
  "Languages": "Idiomas",
  "Welcome to Mausritter Sheet!": "¡Bienvenidos a la ficha de personajes de Mausritter!",
  "under licence": "Bajo licencia",
  "Items": "Objetos",
  "Conditions": "Estados",
  "Birthsign": "Signo de Nacimiento",
  "Coat color": "Color de Pelaje",
  "Coat pattern": "Patrón de Pelaje",
  "Color": "Color",
  "Pattern": "Patrón",
  "Main paw": "Pata principal",
  "Body": "Cuerpo",
  "Off paw": "Pata secundaria",
  "Carried: Ready to use.": "Preparado: Listo para usar.",
  "Worn: Quick to ready.": "Portado: Uso rápido.",
  "Pack: Takes time to ready. During combat, requires an action to retrieve.": "Equipaje: Se requiere tiempo para prepararlo. Durante el combate, cuesta una acción encontrar un objeto.",
  "Name": "Nombre",
  "Background": "Trasfondo",
  "Coat": "Pelaje",
  "Look": "Rasgos Físicos",
  "STR": "FUE",
  "DEX": "DES",
  "WIL": "VOL",
  "STR ⇄ DEX": "FUE ⇄ DES",
  "STR ⇄ WIL": "FUE ⇄ VOL",
  "DEX ⇄ WIL": "DES ⇄ VOL",
  "No swap.": "Sin cambios.",
  "{price}p": "{price} p",
  "HP": "PG",
  "Max": "Max",
  "Current": "Actual",
  "Inventory": "Inventario",
  "Level": "Nivel",
  "XP": "XP",
  "Grit": "Agallas",
  "Ignore a number of conditions equal to your Grit.": "Ignora un número de estados igual a tus agallas.",
  "Bank": "Banco",
  "Pips": "Pepitas",
  "Roll:": "Tira :",
  "Roll: ": "Tira : ",
  "About": "Acerca de",
  "This application is an independent production by Daniel Coquette and is not affiliated with Losing Games. It is published under the Mausritter Third Party Licence.": "Esta aplicación es una producción de Daniel Coquette y no está afiliada con « Losing Games ». Esta aplicación está publicada bajo « Mausritter Third Party Licence ».",
  "Mausritter is copyright Losing Games.": "Mausritter es propiedad intelectual de Losing Games",
  "This application is copyright Daniel Coquette.": "Esta applicación es propiedad intelectual de Daniel Coquette.",
  "Sheet and drawer backgrounds from DevianArt under license": "Las imágenes de fondo y de la ficha han sido extraídas de DevianArt bajo licencia",
  "Close": "Cerrar",
  "Neutral": "Neutral",
  "With advantage": "Con ventaja",
  "With disadvantage": "Con desventaja",
  "Need {score} or lower, roll {roll}.": "Necesitas {score} o más, sacaste {roll}.",
  "Need upper than {upper}, roll {roll}.": "Necesitas más de {upper}, sacaste {roll}.",
  "Create a new character...": "Crear un nuevo personaje...",
  "Load": "Cargar",
  "Save": "Guardar",
  "Saves": "Guardados",
  "Delete": "Eliminar",
  "Empty": "Vacío",
  "SUCCESS": "ÉXITO",
  "FAILED": "FRACASO",
  "Clear:": "Vaciar:",
  "STR save": "Salvación de FUE",
  "DEX save": "Salvación de DES",
  "WIL save": "Salvación de VOL",
  "Choose...": "Escoge...",
  "Improvised": "Improvisada",
  "Weapon": "Arma",
  "Needle": "Aguja",
  "Dagger": "Daga",
  "Axe": "Hacha",
  "Sword": "Espada",
  "Mace": "Maza",
  "Hammer": "Martillo",
  "Warhammer": "Martillo de guerra",
  "Spear": "Lanza",
  "Hookarm": "Lanza-Garfio",
  "Bow": "Arco",
  "Arrows": "Flechas",
  "Sling": "Honda",
  "Stones": "Piedras",
  "Heavy Armour": "Armadura Pesada",
  "Light Armour": "Armadura Ligera",
  "Torches": "Antorchas",
  "Lantern": "Linterna",
  "Electric Lantern": "Linterna Eléctrica",
  "Pip Purse": "Bolsa de pepitas",
  "Rations": "Raciones",
  "Spell": "Hechizo",
  "Item": "Objeto",
  "A custom item.": "Un objeto personalizado.",
  "The sheet of {name} will be erased. Do you confirm?": "La ficha de « {name} » será eliminada. ¿Estás seguro?",
  "Confirm": "Confirmación",
  "Yes": "Sí",
  "No": "No",
  "OK": "OK",
  "Def": "Def",
  "Items and conditions drawer.": "Cajón de objetos y estados",
  "Roll {dice}": "Lanzar {dice}",
  "{name} prepares for adventure...": "« {name} » se prepara para la aventura…",
  "You may swap any two attributes.": "Puedes intercambiar dos atributos entre sí.",
  "You may choose a weapon below:": "Puedes escoger un arma de la siguiente selección:",
  "You can take one item below:": "Puedes tomar un objeto de la siguiente selección :",
  "Item from inherited your background of «{background}»": "Objetos heredados de tu pasado como « {background} »",
  "Your mouse must pay a fee of 1% of the value when retrieving the stored pips or items.": "Para retirar pepitas u objetos, tu ratón debe pagar una cuota del 1% de su valor .",
  "Retrieving {name} from your bank will cost you {fee} pips. Confirm?": "Retirar « {name} » de tu banco te costará {fee} pepitas. Aceptas el costo ?",
  "You do need to have {fee} pips to retrieve {name}!": "¡Necesitas {fee} pepitas para retirar « {name} » !",
  "One paw": "Una pata",
  "Both paws": "Dos patas",
  "History": "Historial",
  "Manage your characters.": "Administrar tus personajes.",
  "The sheet of {dest} will be overwritten by {name}. Do you confirm?": "La ficha de « {dest} » será reemplazada por la de « {name} » . ¿Estás seguro?",
  "Do you want to save the sheet of {name}?": "¿Quieres guardar la ficha de « {name} » ?",
  "Welcome": "Bienvenido",
  "Hirelings": "Ayudantes",
  "Recruit hireling...": "Reclutar un ayudante…",
  "Wages/day": "Paga / día",
  "Dismiss": "Despedir",
  "The hireling of {desc} {name} will be dismissed. Do you confirm?": " « {desc} {name} » será despedido. ¿Estás seguro ?",
  "Clear": "Vaciar",
  "Export": "Exportar",
  "{name} is now copied to clipboard.": "« {name} » ha sido copiado al portapapeles.",
  "Import...": "Importar…",
  "Paste your character here...": "Pega tu personaje aquí…",
  "Drop item here, in your bank.": "Coloca aquí los objetos que quieres depositar en el banco.",
  "Advancement...": "Subida de nivel…",
  "Level up": "Subir de nivel",
  "{name} prepares advancement...": "« {name} » se prepara para subir de nivel…",
  "{name} will move from the {current} level to the {next} level.": "« {name} » pasará del nivel {current} al nivel {next}.",
  "Short rest...": "Descanso corto…",
  "Short rest": "Descanso corto",
  "Long rest...": "Descanso largo…",
  "Long rest": "Descanso largo",
  "Full rest...": "Descanso completo",
  "Full rest": "Descanso completo",
  "A short rest lasts 1 turn and restore d6+1 HP. Do you confirm?": "Un descanso corto dura 1 turno y restaura d6+1 PG ¿Quieres descansar?",
  "A full rest is a week back in safety. It will cost you around 20 pips. Do you confirm?": "Un descanso completo dura una semana en un lugar seguro y cuesta 20 pepitas. ¿Quieres descansar ?",
  "Current HP is {value}.": "El PG actual es : {value}.",
  "You need to eat and sleep for a watch to do a long rest. All HP restored. Restore d6 to each attributes if HP was at max. Do you confirm?": "Necesias comer y dormir por una guardia para lograr un descanso largo. Restaurará todos los PG. Si tus PG están al máximo, restaurarás d6 a cada atributo. ¿Quieres descansar ?",
  "Restored all HP.": "PG restaurado al máximo.",
  "Restored all attributes.": "Todos los atributos han sido restaurados.",
  "Restored some attributes.": "Algunos atributos restaurados.",
  "Current STR is {value}.": "FUE actual : {value}.",
  "Current DEX is {value}.": "DES actual : {value}.",
  "Current WIL is {value}.": "VOL actual : {value}.",
  "Do not forget to remove the conditions corresponding to this rest.": "No olvides quitar los estados correspondientes despúes de este descanso.",
  "Increase in attributes if d20 is higher than the maximum of the latter.": "Obtén un aumento de atributos si d20 es mayor al valor más alto de tus atributos.",
  "If {roll} is greater than your maximum HP, then your HP is the sum of the dice. Otherwise they increase by one.": "Si {roll} es mayor que el máximo de tus PG, tu PG será la suma de los dados. De otra forma, tus PG aumentan un punto.",
  "Roll {formula} is {roll}. Max {attr} is {value}.": "La {formula} de tiraje es {roll}. El máximo de {attr} es {value}.",
  "Increase {attr} to {result}.": "Aumenta {attr} a {result}.",
  "{attr} will not increase.": "{attr} no aumentará.",
  "You're encumbered. You cannot run, and makes all saves with disadvantage.": "Estás en tu límite de carga, no puedes correr, y todas tus salvaciones se harán con desventaja.",
  "Condolence, your mouse has passed away.": "Nuestras condolencias, tu ratón ha pasado a mejor vida.",
  "Your mouse cannot move.": "Tu ratón no puede moverse.",
  "Your mouse is succumbing to madness.": "Tu ratón está cayendo en la locura.",
  "Preferences...": "Preferencias…",
  "Application font": "Fuente de la Aplicación",
  "3D dices from Anton Natarov": "Dados en 3D de Anton Natarov :",
  "Welcome, History and Hirelings background from": "Fondo de Bienvenida, Historial y Ayudantes de",
  "App": "App",
  "Mausritter Sheet folder is {folder}": "La carpeta de fichas de Mausritter está en {folder}",
  "level {n}": "nivel {n}",
  "A small custom item.": "Un objeto personalizado pequeño",
  "A tall custom item.": "Un objeto personalizado alto",
  "A wide custom item.": "Un objeto persoalizado ancho",
  "If you leave before saving, your changes will be lost.": "Si te vas sin guardar, tus cambios se perderán.",
  "Table": "Tabla",
  "None": "Ninguno(a)",
  "Damages": "Daños",
  "Please create or load your mouse.": "Por favor crea o carga un ratón."
}