{
  "Fireball": "Boule de feu",
  "Heal": "Soins",
  "Magic Missile": "Projectile magique",
  "Fear": "Effroi",
  "Darkness": "Ténèbres",
  "Restore": "Restauration",
  "Be Understood": "Se faire comprendre",
  "Ghost Beetle": "Scarabée fantôme",
  "Light": "Lumière",
  "Invisible Ring": "Cercle invisible",
  "Knock": "Toc-toc",
  "Grease": "Graisse",
  "Grow": "Croissance",
  "Invisibility": "Invisibilité",
  "Catnip": "Herbe à chat"
}