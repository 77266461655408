{
  "Exhausted": "Fatigue",
  "Frightened": "Effroi",
  "Hungry": "Faim",
  "Injured": "Blessure",
  "Drained": "Épuisé",
  "CONDITION-CLEAR-SR": "Après un repos court",
  "CONDITION-CLEAR-LR": "Après un repos long",
  "CONDITION-CLEAR-FR": "Après un repos complet",
  "CONDITION-CLEAR-ME": "Après un repas",
  "After short rest": "Après un repos court",
  "After long rest": "Après un repos long",
  "After full rest": "Après un repos complet",
  "After meal": "Après un repas",
  "WIL save to approach source of fear.": "Sauvegarde de VOL pour approcher une source de peur.",
  "Disadvantage on STR & DEX saves.": "Désavantage sur les sauvegardes de FOR & DEX.",
  "Disadvantage on WIL saves.": "Désavantage sur les sauvegardes de VOL."
}