{
  "Fireball": "Fireball",
  "Heal": "Heal",
  "Magic Missile": "Magic Missile",
  "Fear": "Fear",
  "Darkness": "Darkness",
  "Restore": "Restore",
  "Be Understood": "Be Understood",
  "Ghost Beetle": "Ghost Beetle",
  "Light": "Light",
  "Invisible Ring": "Invisible Ring",
  "Knock": "Knock",
  "Grease": "Grease",
  "Grow": "Grow",
  "Invisibility": "Invisibility",
  "Catnip": "Catnip"
}