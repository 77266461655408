{
  "Exhausted": "Exhausted",
  "Frightened": "Frightened",
  "Hungry": "Hungry",
  "Injured": "Injured",
  "Drained": "Drained",
  "CONDITION-CLEAR-SR": "After short rest",
  "CONDITION-CLEAR-LR": "After long rest",
  "CONDITION-CLEAR-FR": "After full rest",
  "CONDITION-CLEAR-ME": "After meal",
  "After short rest": "After short rest",
  "After long rest": "After long rest",
  "After full rest": "After full rest",
  "After meal": "After meal",
  "WIL save to approach source of fear.": "WIL save to approach source of fear.",
  "Disadvantage on STR & DEX saves.": "Disadvantage on STR & DEX saves.",
  "Disadvantage on WIL saves.": "Disadvantage on WIL saves."
}