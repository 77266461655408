{
  "Fireball": "Огненный шар",
  "Heal": "Исцеление",
  "Magic Missile": "Магический снаряд",
  "Fear": "Страх",
  "Darkness": "Тьма",
  "Restore": "Восстановление",
  "Be Understood": "Открытое сердце",
  "Ghost Beetle": "Жук-призрак",
  "Light": "Свет",
  "Invisible Ring": "Невидимое кольцо",
  "Knock": "Взлом",
  "Grease": "Скольжение",
  "Grow": "Рост",
  "Invisibility": "Невидимость",
  "Catnip": "Кошачья мята"
}